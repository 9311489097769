import { USER_FIELDS } from '@/constants/users';

export default function(data = {}) {
    USER_FIELDS.forEach(i => {
        this[i] = data[i] || ''
    })
    this.active = data.active || false
    this.banned = data.banned || false
    this.passwordTtl = data.passwordTtl || null;
    this.organization = { id: '', type: 'organization' }
    this.role = { id: '', type: 'role' }
}
