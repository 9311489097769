<template>
    <form class="my-form edit-form">
        <div class="p-fluid form-fields p-grid p-mx-0 p-my-2">
            <div class='p-col-6 left-col p-p-0'>
                <div class="p-field">
                    <label class='field-label'>Фамилия*</label>
                    <InputText name="lastName" v-model.trim="v$.formData.lastName.$model" placeholder="Напишите фамилию" :class="v$.formData.lastName.$invalid && submitted ? 'p-error' : ''" />
                    <small
                        v-show="submitted"
                        v-for="error in showErrors(v$.formData.lastName)"
                        :key="error.$uid"
                        class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>Имя*</label>
                    <InputText name="firstName" v-model.trim="v$.formData.firstName.$model" placeholder="Напишите имя" :class="v$.formData.firstName.$invalid && submitted ? 'p-error' : ''" />
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.firstName)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>Отчество</label>
                    <InputText name="patronymic" v-model="formData.patronymic"
                               placeholder="Напишите отчество" />
                </div>
                <div class="p-field">
                    <label class='field-label'>Организация</label>
                    <Dropdown
                        v-model='formData.organization.id'
                        :disabled='createUserForOrg'
                        option-value='id'
                        placeholder='Выберите из списка организаций'
                        :options='organizations'
                        option-label='publicName'
                        data-key='id'
                        filter
                        @filter='debouncedInputFilter'
                    ></Dropdown>
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.organization.id)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>Должность</label>
                    <InputText name="workPosition" v-model.trim="v$.formData.workPosition.$model" placeholder="Напишите должность" :class="v$.formData.workPosition.$invalid && submitted ? 'p-error' : ''" />
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.workPosition)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>Телефон</label>
                    <InputText name="phone" v-model.trim="v$.formData.phone.$model" placeholder="Введите номер телефона" :class="v$.formData.phone.$invalid && submitted ? 'p-error' : ''" />
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.phone)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
            </div>
            <div class='p-col-6 right-col p-p-0'>
                <div class="p-field">
                    <label class='field-label'></label>
                    <div class='p-d-flex p-ai-center field-checkbox'>
                        <Checkbox
                            :binary="true"
                            v-model='v$.formData.active.$model'
                        />
                        <div class='field-checkbox-label'>Активный</div>
                    </div>
                </div>
                <div class="p-field">
                    <label class='field-label'>Логин*</label>
                    <InputText
                        name="login"
                        v-model.trim="v$.formData.login.$model"
                        placeholder="Напишите логин"
                        :disabled='isEditMode'
                        :class="v$.formData.login.$invalid && submitted ? 'p-error' : ''"
                    />
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.login)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>E-mail*</label>
                    <InputText
                        name="email"
                        v-model.trim="v$.formData.email.$model"
                        placeholder="Напишите email"
                        :disabled="!canEditEmail"
                        :class="v$.formData.email.$invalid && submitted ? 'p-error' : ''"
                    />
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.email)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>Пароль<span v-show="!isEditMode">*</span></label>
                    <Password
                        :feedback="false"
                        name="password"
                        v-model.trim="v$.formData.password.$model"
                        placeholder="Напишите текущий пароль"
                        autocomplete="true"
                        :class="v$.formData.password.$invalid && submitted ? 'p-error' : ''"
                    />
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.password)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>Период действия пароля</label>
                    <Dropdown
                        v-model='formData.passwordTtl'
                        :options='passwordTtls'
                        optionLabel='name'
                        option-value='id'
                        data-key='id'
                        placeholder='Бессрочно'
                    />
                </div>
                <div class="p-field">
                    <label class='field-label'>Роль</label>
                    <Dropdown
                        v-model='formData.role.id'
                        :options='filteredRoles'
                        :disabled='!canViewRoles'
                        option-label='name'
                        option-value='id'
                        placeholder='Выберите из списка'
                        data-key='id'
                        filter
                        @change="selectRole"
                    />
                </div>
            </div>

        </div>
    </form>
</template>

<script>
import User from '@/models/User';
import { helpers, maxLength, minLength, required } from '@vuelidate/validators';
import { USER_FIELD_NAMES, USER_FIELDS, USER_PASSWORD_TTL } from '@/constants/users';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { USER_PERMISSIONS_MAP } from '@/constants/common';
import { getUserById, patchUser, registerNewUser } from '@/api/user';
import { jsonApiListParser } from '@/main/utils/common';
import { pushOrUpdate } from '@/utils/common';
import { getOrganizations } from '@/api/organization';
import { getAllRoles as getRoles } from '@/api/role';
import { requestToastHandler } from '@/main/mixins';

const {
    WORK_POSITION,
    LOGIN,
    PHONE,
    PASSWORD,
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    PATRONYMIC
} = USER_FIELD_NAMES

const { rbacRead, rbacWrite, userWriteAll } = USER_PERMISSIONS_MAP

export default {
    name: 'Params',
    mixins: [requestToastHandler],
    props: {
        userId: {
            type: String,
            default: null
        },
        isEditMode: {
            type: Boolean,
            default: false
        },
        orgId: {
            type: [String, Number],
            default: ''
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символа(ов)` },
        });
        // const notHaveToMatch = () => this.formData.login !== this.formData.passwordConfirm;
        // let passwordValidation
        // if (this.isEditMode ) {
        //     passwordValidation = {
        //         minLength: { ...minLength(6), $message: 'Значение не должно быть короче 6 символов' },
        //     }
        // }
        const passValid = () => {
            const validObj = {
                minLength: { ...minLength(6), $message: 'Значение не должно быть короче 6 символов' },
                maxLength: { ...maxLength(72), $message: 'Значение не должно быть больше 72 символов' },
                notSameAs: helpers.withMessage('Логин и пароль не должны совпадать.', (val) => val !== this.formData.login)
            }
            if (!this.isEditMode || !!this.formData.password) validObj.required = { ...required, $message: 'Поле обязательно к заполнению' }
            return validObj
        }
        // const telephoneMask = () => /^((8|\+7)[- ]?)?(\(?\d{3,4}\)?[- ]?)?[\d\- ]{7,8}$/.test(this.formData.phone);
        const telephoneMask = (val) => val ? (/^[0-9-+)( ]+$/.test(val) && val.length > 1 && val.length < 256) : true;
        const loginMask = () => /^[a-z0-9-_]+$/i.test(this.formData.login) && this.formData.login.length < 256
        const validateEmail = (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        return {
            formData: {
                [WORK_POSITION]: {},
                [LOGIN]: { ...standart(2), loginMask: helpers.withMessage('Логин не подходит', loginMask) },
                [PHONE]: { telephoneMask: helpers.withMessage('Неверный формат телефона', telephoneMask) },
                [PASSWORD]: passValid(),
                // passwordConfirm: {
                //     ...standart(5),
                //     sameAsPassword: {
                //         ...sameAs(this.formData.password),
                //         $message: 'Пароли должны совпадать',
                //     },
                //     notHaveToMatch: helpers.withMessage('Логин и пароль не должны совпадать', notHaveToMatch),
                // },
                [FIRST_NAME]: { required: { ...required, $message: 'Поле обязательно к заполнению' }, minLength: { ...minLength(2), $message: `Значение не должно быть короче ${2} символов` } },
                [LAST_NAME]: { required: { ...required, $message: 'Поле обязательно к заполнению' }, minLength: { ...minLength(2), $message: `Значение не должно быть короче ${2} символов` } },
                [EMAIL]: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    validateEmail: helpers.withMessage('Введите корректный e-mail', validateEmail)
                },
                role: { id: {} },
                organization: { id: {}},
                active: {},
                banned: {},
                sendNewPassEmail: {},
                autoPassRefresh: {},
                passwordTtl: {}
            },
        };
    },
    computed: {
        ...mapGetters('auth', ['userHasRoles', 'userPermissionsObject']),
        formIsValid() {
            return !this.v$.$invalid;
        },
        selectedOrg() {
            return this.organizations.find(i => i.id === this.formData?.organization?.id) || null
        },
        canViewRoles() {
            return this.userHasRoles([rbacWrite, rbacRead])
        },
        canEditEmail() {
            return [ userWriteAll ].some(p => this.userPermissionsObject[p]);
        }
    },
    data () {
        this.createUserForOrg = !!this.orgId
        this.passwordTtls = Object.values(USER_PASSWORD_TTL);
        return {
            wasActive: false,
            wasBanned: false,
            formData: new User(),
            submitted: false,
            filteredRoles: [],
            organizations: [],
            roles: [],
            loadingOrgs: false,
            filterInputTimeout: null,
            mainOrg: {}
        }
    },
    async created() {
        this.$root.$emit('loadingChange', true);
        try {
            await Promise.all([
                this.getAllOrganizations(),
                this.getAllRoles(),
            ])
            await this.handleUserData()
        } catch (error) {
            this.$requestError(error.message);
        }

        this.$root.$emit('loadingChange', false);
        this.$emit('publish', { acceptAction: this.onSubmit })
        this.$emit('change-data', false)
    },
    methods: {
        async handleUserData() {
            if (this.$route.params.orgId) {
                this.formData.organization = {
                    id: this.$route.params.orgId,
                    type: 'organization'
                }
            }
            if (this.userId) {
                const { data: { attributes: userData, relationships: { role, organization } }, included } = await getUserById(this.userId);
                USER_FIELDS.forEach((key) => {
                    this.formData[key] = userData[key];
                });
                this.formData.active = userData.banned ? false : userData.active;
                this.wasActive = userData.active
                this.formData.banned = userData.banned
                this.wasBanned = userData.banned
                this.formData.passwordTtl = userData.passwordTtl ? userData.passwordTtl : 'P0D';
                if (role.data) {
                    this.formData.role.id = role.data.id
                    const filteredRoles = included.filter(i => i.type === 'role' && i.id === role.data.id)
                    const [roleObj] = jsonApiListParser(filteredRoles)
                    pushOrUpdate(this.filteredRoles, roleObj)
                }
                if (organization.data) {
                    this.formData.organization.id = organization.data.id
                    const orgObj = included.find(i => i.id === organization.data.id)
                    const [org] = jsonApiListParser([orgObj], included)
                    pushOrUpdate(this.organizations, org)
                }
            }
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        checkRole() {
            if (!this.formData.role.id) this.formData.role.id = '';
        },
        selectOrganization($event) {
            this.formData.organization.id = $event.value.id;
            this.formData.organization.type = $event.value.type;
        },
        async getAllOrganizations() {
            const filter = {}
            if (this.$route.params.orgId) {
                filter['id'] = {
                    $eq: this.$route.params.orgId
                }
            }
            const { organizations, included } = await getOrganizations({ pageSize: 20, filter, relationShip: true });
            const orgs = jsonApiListParser(organizations, included)
            this.organizations.push(...orgs);
        },
        async getAllRoles() {
            if (!this.canViewRoles) return false
            const { roles } = await getRoles();
            this.roles = jsonApiListParser(roles);
            this.filteredRoles = this.roles;
        },
        async onSubmit() {
            this.submitted = true;
            const dataToServer = {
                data: {
                    type: 'user',
                    attributes: {
                        email: this.formData[EMAIL],
                        login: this.formData[LOGIN],
                        password: this.formData[PASSWORD] || undefined,
                        firstName: this.formData[FIRST_NAME],
                        lastName: this.formData[LAST_NAME],
                        patronymic: this.formData[PATRONYMIC],
                        phone: this.formData[PHONE],
                        workPosition: this.formData[WORK_POSITION],
                        active: this.formData.active,
                        banned: this.formData.banned,
                        passwordTtl: this.formData.passwordTtl === 'P0D' ? null : this.formData.passwordTtl,
                    },
                    relationships: {
                        organization: {
                            data: {
                                type: this.formData.organization.type,
                                id: this.formData.organization.id,
                            },
                        },
                        role: {
                            data: {
                                type: this.formData.role.type,
                                id: this.formData.role.id || null,
                            },
                        },
                    },
                },
            };

            if (this.formIsValid) {
                this.$root.$emit('loadingChange', true)
                try {
                    const result = this.userId ? await patchUser(dataToServer, this.userId) : await registerNewUser(dataToServer);
                    if (result.message) {
                        this.$requestError(result.message);
                        return;
                    }

                    this.v$.$anyDirty = false;
                    this.$emit('change-data', false)
                    this.$toast.add({
                        severity: 'success',
                        summary: !this.userId ? 'Пользователь успешно добавлен' : 'Изменения сохранены',
                        life: '2200',
                    });
                    if (!this.userId) {
                        await this.$router.push(`/users/edit/${ result.data.data.id }`);
                    } else {
                        this.$emit('show-rights');
                    }
                } catch (error) {
                    this.$requestError(error.message);
                    throw new Error(error.message)
                } finally {
                    this.$root.$emit('loadingChange')
                }
            }
        },
        selectRole() {
            !!this.userId &&
                this.$emit('show-rights', this.formData.role.id === 'branch_user' || this.formData.role.id === 'head_user');
        },
        debouncedInputFilter ({ value }) {
            clearTimeout(this.filterInputTimeout)
            this.filterInputTimeout = setTimeout(() => {
                this.updateOrgList(value)
            }, 500)
        },
        async updateOrgList(val) {
            if (this.loadingOrgs) return
            this.$root.$emit('loadingChange', !(val && val.length < 3))
            const userOrg = this.organizations.find(i => i.id === this.formData.organization.id)
            if (val) {
                if (val.length < 3) return
                try {
                    this.loadingOrgs = true
                    const { organizations, included } = await getOrganizations({
                        relationShip: true,
                        filter: {
                            '$or': [
                                { 'name.shortName': { '$ilike': val } },
                                { 'name.fullName': { '$ilike': val } },
                                { 'parent.name.fullName': { '$ilike': val } },
                                { 'parent.name.shortName': { '$ilike': val } }
                            ]
                        }
                    })
                    this.organizations = jsonApiListParser(organizations, included)
                    if (userOrg) pushOrUpdate(this.organizations, userOrg)
                } catch (e) {
                    console.log(e);
                } finally {
                    this.$root.$emit('loadingChange')
                    this.loadingOrgs = false
                }
                return
            }
            const { organizations, included } = await getOrganizations({ pageSize: 20, relationShip: true });
            this.organizations = jsonApiListParser(organizations, included)
            if (userOrg) pushOrUpdate(this.organizations, userOrg)
            this.$root.$emit('loadingChange')
        }
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.$emit('change-data', true)
            }
        },
        'formData.active': {
            handler(to) {
                if (to) {
                    this.formData.banned = false
                } else if (this.wasActive || this.wasBanned) {
                    this.formData.banned = true
                }
            }
        },
    },
};
</script>
