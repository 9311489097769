<template>
    <div class="p-col-12 p-d-flex p-col-customPad p-flex-column p-px-0 p-py-0">
        <TreeTable
            :value="forms"
            :paginator="true"
            :lazy="true"
            :expandedKeys="expandedForms"
            class="p-datatable-customers p-datatable-striped organization-list"
            :class="{ showFilters: showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            :page-link-size='5'
            :filters="filters"
            selectionMode="single"
            :scrollable="true"
            scroll-height="calc(100vh - 400px)"
            @filter="selectedFilter"
            @sort="onSort"
            @page="onPage"
            resizable-columns
            :contentStyle='{ overflow: "visible" }'
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                                <span class="p-float-label">
                                    <InputText
                                        type="text"
                                        v-model="filters.name"
                                        placeholder="Поиск"
                                        @input="selectedFilter"
                                    />
                                </span>
                            <span class="p-inputgroup-addon">
                                    <i class="pi pi-search"></i>
                                </span>
                        </div>
                        <div class="p-p-0">
                            <Button @click="sendData" class="p-button p-ml-3">Сохранить</Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Формы не найдены</template>
            <template #loading> Загружается список форм. Пожалуйста подождите</template>
            <Column
                header="Наименование"
                field="name"
                :sortable="true"
                :expander="true"
                header-class="header-name"
                body-class="body-name p-text-nowrap p-text-truncate"
                filter-match-mode='contains'
            >
                <template #body="slotProps">
                    <span :title="slotProps.node.name" :class="slotProps.node.archive ? 'archive' : ''">{{ slotProps.node.name }}</span>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText
                        v-show="showFilters"
                        type="text"
                        v-model="filters.name"
                        class="p-column-filter p-my-2"
                        placeholder="Наименование"
                        @input="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                v-for='perm in permissions'
                :key='perm'
                :field='`permissions.${perm}`'
                body-class='form-permission-checkbox'
                header-class='form-permission-header'
            >
                <template #header>
                    <div class="p-field-checkbox" style="margin-bottom: 0;">
                        <Checkbox :id='`all_${perm}`'
                                  v-model='allPermissions[perm]'
                                  binary
                                  :disabled="!canEditFormRights"
                                  @change="checkboxAllPermissionsHandler(perm)"
                        />
                        <label :for='`all_${perm}`'>{{ formData[perm].label }}</label>
                    </div>
                </template>
                <template #body='slotProps'>
                    <Checkbox v-if="!(['approve', 'distribute', 'report'].includes(perm) && slotProps.node.type === 'form-group') && slotProps.node.permissions"
                        v-model='slotProps.node.permissions[perm]'
                        binary
                        :disabled="!canEditFormRights"
                        @change="checkboxPermissionsHandler(slotProps.node, perm)"
                    />
                </template>
            </Column>
            <template #paginatorLeft />
        </TreeTable>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import { mapGetters } from 'vuex';
import {
    addFormPermissions,
    deleteFormPermissions,
    fetchFormPermissions,
    getAllForms,
    getFormGroupPermissions
} from '@/api/form';
import { jsonApiListParser } from '@/main/utils/common';
import { USER_FORM_PERMISSIONS_NAMES, USER_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';
import FormPermissions from '@/models/FormPermissions';
import SubmitJsonApi from '@/models/SubmitJsonApi';

const {
    formWrite
} = USER_PERMISSIONS_MAP

export default {
    name: 'FormPermissions',
    mixins: [requestToastHandler],
    props: {
        userId: {
            type: String,
            required: true
        },
        isEditMode: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        selectedForm() {
            return this.forms.find((item) => item.id === this.editItemId) || { id: '' }
        },
        canEditFormRights() {
            return [ formWrite ].some(p => this.userPermissionsObject[p]);
        },
    },
    async created() {
        try {
            this.$root.$emit('loadingChange', true);
            await this.getFormPermissions();
            await this.getAllForms()
        } catch (error) {
            this.$requestError(error.message);
        } finally {
            this.$root.$emit('loadingChange', false);
        }
        this.$emit('publish', { acceptAction: this.sendData });
        this.$emit('change-data', false);
    },
    data() {
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            formData: new FormPermissions(),
            permissions: USER_FORM_PERMISSIONS_NAMES,
            allPermissions: [],
            forms: [],
            showFilters: false,
            totalRecords: null,
            filters: {},
            serverFilters: {},
            filterTimeout: null,
            sortField: '',
            selectedPermissions: [],
            userFormPermissions: [],
            noParsedPermissions: [],
            userFormGroupPermissions: [],
            noParsedGroupPermissions: [],
            permissionsForDelete: [],
            permissionsForAdd: [],
            sendDataTimeout: null,
            currentPage: 1,
            expandedForms: {}
        }
    },
    methods: {
        async checkboxAllPermissionsHandler(permissionName) {
            const permissionValue = this.allPermissions[permissionName];

            for (let object of this.forms) {
                console.log(object);
                let permission = null;
                object.permissions[permissionName] = permissionValue;
                if (['form', 'monitoring'].includes(object.type)) {
                    permission = this.getNoParsedFormPermission(object.id, permissionName);
                }
                if (object.type === 'form-group') {
                    permission = this.getNoParsedGroupPermission(object.id, permissionName);
                }
                if (permissionValue) {
                    if (permission) {
                        this.removePermission(permission);
                    }

                    if (!permission) {
                        this.addPermission(object, permissionName);
                    }

                    if (['form', 'monitoring'].includes(object.type) && object.children) {
                        object.children.map((group) => {
                            if (!group.permissions[permissionName]) {
                                let groupPermission = this.getNoParsedGroupPermission(group.id, permissionName);
                                group.permissions[permissionName] = true;

                                if (groupPermission) {
                                    this.clearRemoves(group, permissionName);
                                }
                                if (!groupPermission) {
                                    this.addPermission(group, permissionName);
                                }
                            }
                        });
                    }
                }
                if (!permissionValue) {
                    if (permission) {
                        this.permissionsForDelete.push(permission);
                    }

                    if (!permission) {
                        this.clearAdds(object, permissionName);
                    }
                    if (['form', 'monitoring'].includes(object.type) && object.children) {
                        object.children.map((group) => {
                            group.permissions[permissionName] = false;
                            let groupPermission = this.getNoParsedGroupPermission(group.id, permissionName);

                            if (groupPermission) {
                                this.permissionsForDelete.push(groupPermission);
                            } else {
                                this.clearAdds(group, permissionName);
                            }
                        });
                    }
                }
            }
        },
        async checkboxPermissionsHandler(object, permissionName) {
            const permissionValue = object.permissions[permissionName];
            let permission = null;
            if (['form', 'monitoring'].includes(object.type)) {
                permission = this.getNoParsedFormPermission(object.id, permissionName);
            }
            if (object.type === 'form-group') {
                permission = this.getNoParsedGroupPermission(object.id, permissionName);
            }

            if (permissionValue) {
                if (permission) {
                    this.removePermission(permission);
                }

                if (!permission) {
                    this.addPermission(object, permissionName);
                }

                if (['form', 'monitoring'].includes(object.type) && object.children) {
                    object.children.map((group) => {
                        if (!group.permissions[permissionName]) {
                            let groupPermission = this.getNoParsedGroupPermission(group.id, permissionName);
                            group.permissions[permissionName] = true;

                            if (groupPermission) {
                                this.clearRemoves(group, permissionName);
                            }
                            if (!groupPermission) {
                                this.addPermission(group, permissionName);
                            }
                        }
                    });
                }

                if (object.type === 'form-group') {
                    let formNode = this.forms.find((item) => item.id === object.root);
                    let allGroupPermissions = formNode?.children.filter(group => group.permissions[permissionName]);

                    if (allGroupPermissions.length === formNode.children.length && !formNode.permissions[permissionName]) {
                        formNode.permissions[permissionName] = true;
                        const formPermission = this.getNoParsedFormPermission(object.id, permissionName);

                        if (formPermission) {
                            this.clearRemoves(formNode, permissionName);
                        }

                        if (!formPermission) {
                            this.addPermission(formNode, permissionName);
                        }
                    }
                }
            }

            if (!permissionValue) {
                this.allPermissions[permissionName] = permissionValue;
                if (permission) {
                    this.permissionsForDelete.push(permission);
                }

                if (!permission) {
                    this.clearAdds(object, permissionName);
                }

                if (['form', 'monitoring'].includes(object.type) && object.children) {
                    object.children.map((group) => {
                        group.permissions[permissionName] = false;
                        let groupPermission = this.getNoParsedGroupPermission(group.id, permissionName);

                        if (groupPermission) {
                            this.permissionsForDelete.push(groupPermission);
                        } else {
                            this.clearAdds(group, permissionName);
                        }
                    });
                }

                if (object.type === 'form-group') {
                    let formNode = this.forms.find((item) => item.id === object.root);
                    if (formNode.permissions[permissionName]) {
                        formNode.permissions[permissionName] = false;
                        const formPermission = this.getNoParsedFormPermission(formNode.id, permissionName);

                        if (formPermission) {
                            this.permissionsForDelete.push(formPermission);
                        }

                        if (!formPermission) {
                            this.clearAdds(formNode, permissionName);
                        }
                    }
                }
            }
        },

        addPermission(object, permissionName) {
            const type = ['form', 'monitoring'].includes(object.type) ? 'form' : 'form-group';
            const permissionObject = {
                year: null,
                operation: permissionName
            }
            if (type === 'form') {
                permissionObject.form = {
                    type,
                    id: object.id
                };
            } else {
                permissionObject.group = {
                    type,
                    id: object.id
                }
            }
            const { data: permission } = new SubmitJsonApi(permissionObject, `${ type }-verifier`);
            this.permissionsForAdd.push(permission);
        },

        removePermission(permission) {
            const idx = this.permissionsForDelete.indexOf(permission);
            if (idx >= 0) {
                this.permissionsForDelete.splice(idx, 1);
            }
        },

        clearAdds(object, permissionName) {
            let permissionIdx = this.permissionsForAdd.findIndex(p => p.attributes.operation === permissionName
                && p.relationships.form?.data?.id === object.id);
            if (permissionIdx < 0) {
                permissionIdx = this.permissionsForAdd.findIndex(p => p.attributes.operation === permissionName
                    && p.relationships.group?.data?.id === object.id);
            }
            if (permissionIdx >= 0) {
                this.permissionsForAdd.splice(permissionIdx, 1);
            }
        },

        clearRemoves(object, permissionName) {
            let permissionIdx = this.permissionsForDelete.findIndex(p => p.attributes.operation === permissionName
                && p.relationships.form?.data?.id === object.id);
            if (permissionIdx < 0) {
                permissionIdx = this.permissionsForDelete.findIndex(p => p.attributes.operation === permissionName
                    && p.relationships.group?.data?.id === object.id);
            }
            if (permissionIdx >= 0) {
                this.permissionsForDelete.splice(permissionIdx, 1);
            }
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.debouncedFilter();
        },

        debouncedFilter() {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(async () => {
                await this.getAllForms();
            }, 1000);
        },

        async getFormPermissions() {
            try {
                const { data, included } = await fetchFormPermissions(this.userId)
                this.userFormPermissions = jsonApiListParser(data, included);
                this.noParsedPermissions = data
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        getNoParsedFormPermission(formId, permissionName) {
            return this.noParsedPermissions.find(p => {
                return p.attributes?.operation === permissionName && p.relationships?.form?.data?.id === formId
            })
        },
        getNoParsedGroupPermission(groupId, permissionName) {
            return this.noParsedGroupPermissions.find(p => {
                return p.attributes?.operation === permissionName && p.relationships?.group?.data?.id === groupId
            })
        },
        async getAllForms() {
            let filter = {};
            if (this.filters.name && this.filters.name.length > 0) {
                filter['name'] = { $like: this.filters.name };
            }

            if (Object.keys(filter).length > 0) {
                let statusFilter = {
                    $and: [
                        filter,
                        {
                            $or: [
                                {
                                    active: { $eq: true },
                                    archive: { $eq: false }
                                },
                                {
                                    active: { $eq: false },
                                    archive: { $eq: true }
                                },
                            ]
                        }
                    ]
                };
                filter = statusFilter;
            } else {
                filter = {
                    $or: [
                        {
                            active: { $eq: true },
                            archive: { $eq: false }
                        },
                        {
                            active: { $eq: false },
                            archive: { $eq: true }
                        },
                    ]
                };
            }

            try {
                this.$root.$emit('loadingChange', true);
                const { forms, included, meta } = await getAllForms({
                    page: this.currentPage,
                    pageSize: this.pageSize,
                    relationShip: true,
                    filter,
                    sort: this.sortField
                });
                this.forms = jsonApiListParser(forms, included);
                for (const form of this.forms) {
                    if (form.groups?.length > 0) {
                        const {
                            data: permissionData,
                            included: permissionIncluded
                        } = await getFormGroupPermissions(this.userId, `formId=${ form.id }`);
                        if (permissionData) {
                            this.userFormGroupPermissions[form.id] = jsonApiListParser(permissionData, permissionIncluded);
                            this.noParsedGroupPermissions = this.noParsedGroupPermissions.concat(permissionData);
                        }
                    }
                }
                this.forms = this.forms.map(f => ({
                    ...f,
                    key: f.id,
                    permissions: this.getPermissionsByFormId(f.id),
                    children: f.groups.length > 0 ? f.groups.map(group => ({
                        ...group,
                        key: group.id,
                        type: 'form-group',
                        permissions: this.getGroupPermissionsByFormId(f.id, group.id),
                        root: f.id
                    })) : []
                }));
                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$root.$emit('loadingChange');
            }
            await this.expandAll();
        },
        async expandAll() {
            for (let node of this.forms) {
                await this.rowExpandHandler(node);
            }
            this.expandedForms = {...this.expandedForms};
        },

        async rowExpandHandler(node) {
            if (node.id) {
                let lazyNode = {...node};

                let groups = [];
                if (node.groups.length > 0) {
                    groups = node.groups.map(group => ({
                        ...group,
                        key: group.id,
                        name: group.name,
                        type: 'form-group',
                        permissions: this.getGroupPermissionsByFormId(node.id, group.id),
                        root: node.id
                    }));
                    this.expandedForms[node.key] = true;
                }

                lazyNode.children = groups;
                this.forms = this.forms.map(n => {
                    if (n.id === node.id) {
                        n = lazyNode;
                    }
                    return n;
                });
            }
        },

        onSort({ sortField, sortOrder }) {
            if (this.permissionsForAdd.length > 0 || this.permissionsForDelete.length > 0) {
                this.$root.$emit('show-leave-dialog', {
                    acceptCb: async () => {
                        await this.sendData();
                        this.sortField = `${sortOrder > 0 ? '' : '-'}${sortField}`;
                        this.debouncedFilter();
                    },
                })
            } else {
                this.sortField = `${sortOrder > 0 ? '' : '-'}${sortField}`;
                this.debouncedFilter();
            }
        },

        onPage({ page, rows }) {
            if (this.permissionsForAdd.length > 0 || this.permissionsForDelete.length > 0) {
                this.$root.$emit('show-leave-dialog', {
                    acceptCb: async () => {
                        await this.sendData();
                        this.pageSize = rows;
                        this.currentPage = page + 1;
                        this.debouncedFilter();
                    },
                })
            } else {
                this.pageSize = rows;
                this.currentPage = page + 1;
                this.debouncedFilter();
            }
        },

        getPermissionsByFormId(formId) {
            let resultObj = {};
            this.permissions.forEach(p => {
                resultObj[p] = false;
            });

            this.userFormPermissions.filter(p => p.form.id === formId).forEach(p => {
                resultObj[p.operation] = true
            })
            return resultObj
        },
        getGroupPermissionsByFormId(formId, groupId) {
            let resultObj = {};
            this.permissions.forEach(p => {
                resultObj[p] = false;
            });

            this.userFormGroupPermissions[formId]?.filter(p => p.group.id === groupId).forEach(p => {
                resultObj[p.operation] = true;
            });
            return resultObj;
        },

        async sendData() {
            try {
                this.$root.$emit('loadingChange', true);
                const promises = []
                if (this.permissionsForAdd.length) {
                    promises.push(addFormPermissions(this.userId, this.permissionsForAdd))
                }
                if (this.permissionsForDelete.length) {
                    promises.push(deleteFormPermissions(this.userId, this.permissionsForDelete))
                }
                await Promise.all(promises);
                if (this.permissionsForAdd.length + this.permissionsForDelete.length > 0) {
                    this.$toast.add({
                        severity: 'success',
                        summary: `Изменения были сохранены`,
                        life: '2200',
                    });
                }
                this.permissionsForAdd = []
                this.permissionsForDelete = []
                this.$emit('change-data', false);
                await this.getFormPermissions();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$root.$emit('loadingChange')
            }
        }
    },
    watch: {
        permissionsForAdd: {
            handler(to) {
                if (to.length > 0) {
                    this.$emit('change-data', true);
                }
            }
        },

        permissionsForDelete: {
            handler(to) {
                if (to.length > 0) {
                    this.$emit('change-data', true);
                }
            }
        }

    }
};
</script>

<style lang='scss' scoped>
.organization-list {
    ::v-deep {
        .table-global-search {
            margin-top: 0!important;
        }
        .form-permission-header {
            text-align: center;
            width: 9.4585vw;
            font-weight: 500 !important;
        }
        .form-permission-checkbox {
            width: 9.4585vw;
            .p-checkbox {
                align-self: center;
                justify-self: center;
            }
        }
    }
}

.p-datatable-customers {
    ::v-deep {
        .p-treetable {
            &-header {
                background: #ffffff;
                border: unset;
                padding: 0 0 1rem 0;
                .table-active-period{
                    margin-top: 12px;
                    .p-column-filter {
                        margin-right: 16px;
                    }
                }
            }
            &-thead {
                th.p-filter-column {
                    span {
                        line-height: unset;
                    }
                    &:hover {
                        background: unset !important;
                    }
                }
                & {
                    tr {
                        &:nth-child(2) {
                            height: unset;
                            max-height: 44px;
                        }
                        th {
                            background: #E0E0E0;
                            border: unset;
                            overflow: unset !important;
                            overflow-x: hidden;
                            &:first-child {
                                padding-left: 49px;
                            }
                        }
                    }
                }
            }
            &-tbody {
                tr {
                    height: 44px !important;
                    &:hover {
                        background: #a5dca8 !important;
                    }
                    &:nth-child(even) {
                        background: #eaeaea !important;
                        &:hover {
                            background: #a5dca8 !important;
                        }
                    }
                    td {
                        padding: 0 0 0 1rem;

                        .archive {
                            color: #999;
                        }
                        &:last-child {
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }
        .p-panel-header-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &:hover {
                background: rgba(135, 148, 163, 0.25);
            }
        }
        .entry {
            &-header {
                &-org {
                    width: calc(10vw + 16.2px);
                }
                &-period {
                    width: calc(12vw + 16.2px);
                }
                &-status {
                    width: calc(8vw + 16.2px);
                }
            }
        }
    }
}
</style>
