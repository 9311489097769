export const USERS_TOAST_PREFIXES = {
    EDIT: 'Пользователь успешно отредактирован',
    CREATE: 'Регистрация прошла успешно'
}

export const USERS_FORM_TITLES = {
    EDIT: 'Редактирование пользователя',
    CREATE: 'Создание пользователя'
}

export const USER_FIELD_NAMES = {
    LAST_NAME: 'lastName',
    FIRST_NAME: 'firstName',
    PATRONYMIC: 'patronymic',
    WORK_POSITION: 'workPosition',
    PHONE: 'phone',
    LOGIN: 'login',
    EMAIL: 'email',
    PASSWORD: 'password',
    PASSWORD_TTL: 'passwordTtl'
}

export const USER_FIELDS = Object.values(USER_FIELD_NAMES)

export const USER_STATUS_NAMES = {
    ACTIVE: 'активный',
    NEW: 'новый',
    ARCHIVE: 'архивный'
}

export const USER_STATUSES = [
    { value: 'active', label: 'активный', bgColor: '#A1E6C3' },
    { value: 'new', label: 'новый', bgColor: '#FFBBB3' },
    { value: 'banned', label: 'архивный', bgColor: '#D7D7D7' }
]

export const USER_PASSWORD_TTL = {
    P0D: { id: 'P0D', name: 'Бессрочно'},
    P30D: { id: 'P30D', name: '30 дней' },
    P60D: { id: 'P60D', name: '60 дней' },
    P90D: { id: 'P90D', name: '90 дней' },
    P120D: { id: 'P120D', name: '120 дней' },
    P150D: { id: 'P150D', name: '150 дней' },
    P180D: { id: 'P180D', name: '180 дней' }
}
